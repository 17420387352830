<template>
	<div id="wrapper">
		<form action id="FormRegistration" class="text-left" @submit.prevent="post">
			<div v-if="this.success" class="success-info">
				<p>Udało się wysłać formularz, dziękujemy za wypełnienie i wysłanie formularza</p>
				<div class="close-info" @click="closeAlert('success')">x</div>
			</div>
			<div v-if="this.success == false" class="error-info">
				<p>Przykro nam ale nie udało się wysłać formularza, prosimy spróbować ponownie</p>
				<div class="close-info" @click="closeAlert('error')">x</div>
			</div>
			<div v-if="!this.validCbx" class="error-info">
				<p>Określ przynajmniej jedno prawo z którego chcesz skorzystać</p>
				<div class="close-info" @click="closeAlert('error')">x</div>
			</div>
			<ul>
				<li>Formularz wypełnia osoba, która występuje z wnioskiem;</li>
				<li>Po złożeniu wniosku, TUI może skontaktować się z Panią/Panem i poprosić o odpowiedź na kilka pytań związanych z Pana/Panią Danymi Osobowymi, które umożliwią weryfikację Pana/Pani tożsamości.</li>
				<li>
					FORMULARZ NIE DOTYCZY ZMIANY IMIENIA, NAZWISKA, ADRESU ZAMIESZKANIA, ADRESU E-MAIL, NUMERU TELEFONU NA AKTUALNEJ REZERWACJI. W TYM CELU PROSIMY SKONTAKTOWAĆ SIĘ Z DZIAŁEM OBSŁUGI KLIENTA. Wnioski przesłane za pośrednictwem niniejszego formularza dotyczące zmiany powyższych danych
					na Państwa aktualnej rezerwacji nie będą rozpatrywane.
				</li>
			</ul>
			<p class="text-required-info">
				<small>
					<i>* Pole wymagane</i>
				</small>
			</p>

			<div class="input-container">
				<label for="userName" class="text-left">
					<p>Imię i nazwisko: *</p>
					<input type="text" :value="getUserName" @input="updateName($event.target.value)" id="userName" required />
				</label>
			</div>

			<div class="input-container">
				<label for="userAddress" class="text-left">
					<p>Adres: *</p>
					<input type="text" :value="getUserAddress" @input="updateAddress($event.target.value)" id="userAddress" required />
					<!-- <input type="text" v-model="addressValue"  :value="getUserAddress" @input="updateAddress($event.target.value)" id="userAddress" required /> -->
				</label>
			</div>

			<div class="input-container">
				<label for="emailAddress" class="text-left">
					<p>Adres e-mail: *</p>
					<input type="email" :value="getUserEmail" @input="updateEmail($event.target.value)" id="emailAddress" required />
					<!-- <input type="email" v-model="emailValue" id="emailAddress" required /> -->
				</label>
			</div>

			<div class="input-container">
				<label for="registerNumber" class="text-left">
					<p>Numer rezerwacji:</p>
					<small>(ostatni o ile została zawarta)</small>
					<input type="text" id="registerNumber" :value="getRegisterNumber" @input="updateRegister($event.target.value)" />
					<!-- <input type="text" v-model="registerValue" id="registerNumber" :value="getUserEmail" @input="updateEmail($event.target.value)"/> -->
				</label>
			</div>

			<h4>II. Określ prawo z którego chcesz skorzystać:</h4>
			<p class="law-information">
				<small>
					<i
						>Zgodnie z Informacją o Przetwarzaniu Danych Osobowych masz prawo żądania dostępu do swoich danych oraz do ich sprostowania, ograniczenia przetwarzania danych, usunięcia danych , przeniesienia, wniesienia sprzeciwu oraz wycofania udzielonej wcześniej zgody na przetwarzanie
						danych osobowych.</i
					>
				</small>
			</p>

			<div class="checkbox-container info-checkbox">
				<label for="lawAccessData">
					<input type="checkbox" @change="checkInput('isDataAccess')" id="lawAccessData" />
					<span>Prawo dostępu do danych</span>
					<span class="info-icon">
						<img src="../assets/info-icon.png" />
					</span>
					<div class="info">
						<p>Prawo przysługuje wyłącznie osobie, której dane dotyczą</p>
					</div>
					<div class="arrow"></div>
				</label>
			</div>

			<div class="checkbox-container info-checkbox law">
				<label for="lawRectificationData">
					<input type="checkbox" @change="checkInput('isDataCorrection')" id="lawRectificationData" />
					<span>Prawo do sprostowania danych</span>
					<span class="info-icon">
						<img src="../assets/info-icon.png" />
					</span>
					<div class="info">
						<p>
							FORMULARZ NIE DOTYCZY ZMIANY IMIENIA, NAZWISKA, ADRESU ZAMIESZKANIA, ADRESU E-MAIL, NUMERU TELEFONU NA AKTUALNEJ REZERWACJI. W TYM CELU PROSIMY SKONTAKTOWAĆ SIĘ Z DZIAŁEM OBSŁUGI KLIENTA. Wnioski przesłane za pośrednictwem niniejszego formularza dotyczące zmiany
							powyższych danych na Państwa aktualnej rezerwacji nie będą rozpatrywane.
						</p>
					</div>
					<div class="arrow"></div>
				</label>
				<div v-if="getLawRectificationData" class="law-input">
					<label for="actualData">
						<p>Aktualne dane</p>
						<input type="text" placeholder="Napisz, które dane chcesz poprawić" :value="getActualData" @input="updateActualData($event.target.value)" required id="actualData" />
					</label>

					<label for="newData">
						<p>Poprawne dane</p>
						<input type="text" placeholder="Napisz, na jakie dane chcesz poprawić" :value="getNewData" @input="updateNewData($event.target.value)" required id="newData" />
					</label>
				</div>
			</div>

			<div class="checkbox-container">
				<label for="lawDeleteData">
					<input type="checkbox" @change="checkInput('isDataRemoval')" id="lawDeleteData" />
					<span>Prawo do usunięcia danych</span>
				</label>
			</div>

			<div class="checkbox-container">
				<label for="lawProcessingData">
					<input type="checkbox" @change="checkInput('isDataRestriction')" id="lawProcessingData" />
					<span>Prawo do ograniczenia przetwarzania</span>
				</label>
				<div v-if="getLawProcessingData" class="law-input">
					<label for="legalBasis">
						<p>Podstawa prawna (art. 18 RODO)</p>
						<input type="text" placeholder="Wskaż podstawę prawną" :value="getLegalBasis" @input="updateLegalBasis($event.target.value)" required id="legalBasis" />
					</label>
				</div>
			</div>

			<div class="checkbox-container">
				<label for="lawTarnsferData">
					<input type="checkbox" @change="checkInput('isDataExporting')" id="lawTarnsferData" />
					<span>Prawo do przenoszenia danych</span>
				</label>
				<div v-if="getLawTarnsferData" class="law-input">
					<label for="newAdministrator">
						<p>Nowy administrator</p>
						<input type="text" placeholder="Wskaż administratora" :value="getNewAdministrator" @input="updateNewAdministrator($event.target.value)" required id="newAdministrator" />
					</label>
				</div>
			</div>

			<div class="checkbox-container">
				<label for="lawObjectionData">
					<input type="checkbox" @change="checkInput('isDataObjection')" id="lawObjectionData" />
					<span>Prawo do sprzeciwu</span>
				</label>
			</div>

			<div class="checkbox-container">
				<label for="lawWithdrawAgreementData">
					<input type="checkbox" @change="checkInput('isConsentWithdrawal')" id="lawWithdrawAgreementData" />
					<span>Prawo do wycofania zgody</span>
				</label>
			</div>

			<!-- TODO CHECK GOOGLE - I am not a robot -->
			<!-- <div class="g-recaptcha" data-sitekey="6Lf5dL8UAAAAAF-kDLE9-S1rhLaAmLPDnwFYXSeZ">

      </div>-->
			<div class="form-group">
				<div class="g-recaptcha" data-sitekey="6LeRO7sUAAAAAMDghluj11yiRvhdnxih83QmsCYh" data-callback="recaptcha"></div>
				<p class="text-danger" v-if="cap == false">Pole wymagane</p>
			</div>

			<div class="checkbox-container">
				<label for="lawConfirmData">
					<input type="checkbox" @change="checkInput('isConfirmation')" id="lawConfirmData" required />
					<span>Oświadczam, że informacje zawarte w zgłoszeniu są prawdziwe i jestem upoważniony do jego przesłania.*</span>
				</label>
			</div>

			<button type="submit">Prześlij</button>
		</form>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
	name: 'FormRegistration',
	computed: {
		...mapGetters(['getLawRectificationData', 'getLawProcessingData', 'getLawTarnsferData', 'getUserName', 'getLegalBasis', 'getUserName', 'getUserAddress', 'getUserEmail', 'getRegisterNumber', 'getActualData', 'getNewData', 'getLegalBasis', 'getNewAdministrator']),
	},
	methods: {
		...mapMutations(['updateName', 'updateAddress', 'updateEmail', 'updateRegister', 'updateActualData', 'updateNewData', 'updateLegalBasis', 'updateNewAdministrator']),
		checkInput(name) {
			this.$store.dispatch('checkInput', name);
		},
		post() {
			this.validCbx = this.validateCbx();
			if (!this.validCbx) {
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});
				return;
			}

			if (captcha) {
				this.success = null;
				this.$http
					.post('https://formularze-daneosobowe.tui.pl/Complaint/api/personalData/send', this.$store.state)
					.then((successResponse) => {
						this.success = true;
						this.scrollToTop();
						this.clearData();
						console.log(successResponse);
						captcha = null;
						this.cap = captcha;
					})
					.catch((errorResponse) => {
						this.success = false;
						this.scrollToTop();
						console.log(errorResponse);
					});
			} else {
				captcha = false;
				this.cap = captcha;
			}
		},
		validateCbx() {
			return this.$store.state.isDataAccess || this.$store.state.isDataCorrection || this.$store.state.isDataRemoval || this.$store.state.isDataRestriction || this.$store.state.isDataExporting || this.$store.state.isDataObjection || this.$store.state.isConsentWithdrawal;
		},
		closeAlert(alertName) {
			this.success = null;
		},
		scrollToTop() {
			window.scrollTo(0, 0);
		},
		clearData() {
			this.updateName('');
			this.updateAddress('');
			this.updateEmail('');
			this.updateRegister('');
			this.updateActualData('');
			this.updateNewData('');
			this.updateLegalBasis('');
			this.updateNewAdministrator('');

			this.$store.state.isDataAccess = false;
			this.$store.state.isDataCorrection = false;
			this.$store.state.isDataRemoval = false;
			this.$store.state.isDataRestriction = false;
			this.$store.state.isDataExporting = false;
			this.$store.state.isDataObjection = false;
			this.$store.state.isConsentWithdrawal = false;
			this.$store.state.isConfirmation = false;

			var checkboxes = document.querySelectorAll('input[type=checkbox]');
			checkboxes = [...checkboxes];
			checkboxes.forEach((element) => {
				element.checked = false;
			});
			grecaptcha.reset();
		},
	},
	data() {
		return {
			success: null,
			cap: null,
			validCbx: true,
		};
	},
};
</script>

<style lang="scss" scoped>
#wrapper {
	background-color: #ffffff;
	border: 1px solid #e5e5e5;
	border-radius: 4px;
	clear: both;
	margin: 20px auto 20px auto;
	padding: 0 20px 20px 20px;
	position: relative;
	color: #555;

	.success-info,
	.error-info {
		color: white;
		padding: 20px;
		margin-top: 15px;
		border-radius: 4px;
		p {
			margin: 0px;
			display: inline-block;
			max-width: 80%;
		}

		.close-info {
			float: right;
			width: 20px;
			height: 20px;
			text-align: center;
			cursor: pointer;
			color: white;
		}
	}

	.success-info {
		background-color: green;
	}
	.error-info {
		background-color: red;
	}

	ul {
		margin: 50px 1.5em 1.5em 0px;
		li {
			font-size: 13px;
		}
	}

	.text-required-info {
		margin: 1.6em 0px;
	}

	.input-container {
		margin-top: 1.6em;
		label {
			width: 100%;
			display: inline-block;
			font-weight: 700;
			padding: 2px 0;
			margin: 0px;

			p {
				margin: 0px;
			}
			small {
				display: block;
			}
			input {
				display: inline-block;
				width: 100%;
				max-width: 310px;
				box-shadow: 0 1px 0 #ffffff, inset 0 1px 1px rgba(0, 0, 0, 0.2);
				background-color: #ffffff;
				box-sizing: border-box;
				border: 1px solid #aaaaaa;
				border-bottom-color: #cccccc;
				border-radius: 2px;
				cursor: text;
				margin: 0;
				outline: none;
				padding: 6px 8px;
				vertical-align: middle;
				height: 28px;
			}
		}
	}

	h4 {
		font-size: 1.5em;
		margin-bottom: 1em;
		margin-top: 1em;
		font-weight: 700;
	}

	.law-information {
		margin-bottom: 1.6em;
	}

	.checkbox-container {
		margin-left: 14px;
		color: #555;
		font-weight: 600;

		&.info-checkbox {
			position: relative;

			.info-icon {
				width: 15px;
				height: 15px;
				margin-left: 10px;
				display: inline-block;
				cursor: pointer;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.info {
				position: absolute;
				top: -50px;
				left: 70px;
				display: none;
				z-index: 20;
				background-color: #f3f0ec;
				padding: 10px;
				font-size: 10px;

				p {
					margin-bottom: 0px;
				}
			}
			.arrow {
				width: 20px;
				height: 20px;
				z-index: 20;
				background-color: #f3f0ec;
				position: absolute;
				top: -20px;
				left: 200px;
				display: none;
				transform: rotate(45deg);
			}

			.info-icon:hover ~ .info {
				display: block;
			}

			.info-icon:hover ~ .arrow {
				display: block;
			}
		}

		&.info-checkbox {
			&.law {
				position: relative;

				.info-icon {
					width: 15px;
					height: 15px;
					margin-left: 10px;
					display: inline-block;
					cursor: pointer;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.info {
					position: absolute;
					top: -94px;
					left: 100px;
					display: none;
					z-index: 20;
					background-color: #f3f0ec;
					padding: 10px;
					font-size: 10px;

					p {
						margin-bottom: 0px;
					}
				}
				.arrow {
					width: 20px;
					height: 20px;
					z-index: 20;
					background-color: #f3f0ec;
					position: absolute;
					top: -20px;
					left: 235px;
					display: none;
					transform: rotate(45deg);
				}

				.info-icon:hover ~ .info {
					display: block;
				}

				.info-icon:hover ~ .arrow {
					display: block;
				}
			}
		}

		label {
			input {
				margin-right: 5px;
			}
		}

		.law-input {
			margin-bottom: 30px;
			label {
				display: block;
				p {
					margin-bottom: 0px;
				}

				input {
					width: 100%;
					max-width: 310px;
				}
			}
		}
	}
}

@media all and(max-width: 500px) {
	.g-recaptcha {
		transform: scale(0.77);
		transform-origin: 0 0;
	}
}

@media all and (min-width: 767px) and (max-width: 991px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -115px;
	}
}

@media all and (min-width: 576px) and (max-width: 767px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -177px;
	}
}

@media all and (min-width: 541px) and (max-width: 575px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -156px;
	}
}

@media all and (min-width: 489px) and (max-width: 540px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -177px;
	}
}

@media all and (min-width: 464px) and (max-width: 488px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -199px;
	}
}

@media all and (min-width: 420px) and (max-width: 463px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -221px;
	}
}

@media all and (min-width: 402px) and (max-width: 419px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -240px;
	}
}

@media all and (min-width: 390px) and (max-width: 401px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -260px;
	}
}

@media all and (min-width: 369px) and (max-width: 389px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -280px;
	}
}

@media all and (min-width: 360px) and (max-width: 368px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -302px;
	}
}

@media all and (min-width: 336px) and (max-width: 359px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -323px;
	}
}
@media all and (min-width: 333px) and (max-width: 335px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -344px;
	}
}
@media all and (min-width: 330px) and (max-width: 332px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -365px;
	}
}
@media all and (min-width: 327px) and (max-width: 329px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -384px;
	}
}

@media all and (width: 326px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -405px;
	}
}
@media all and (max-width: 325px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .info {
		top: -202px;
		left: 0px;
	}
}

@media all and(max-width: 447px) {
	#wrapper .checkbox-container {
		margin-left: 0px;
	}
	#wrapper .checkbox-container.info-checkbox .info-icon:hover ~ .info {
		top: -70px;
	}
}

@media all and (max-width: 329px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .arrow {
		left: 232px;
	}
}

@media all and (max-width: 325px) {
	#wrapper .checkbox-container.info-checkbox.law .info-icon:hover ~ .arrow {
		top: -5px;
		left: 10px;
	}
}
</style>
