import Vue from 'vue'
import App from './App.vue'
import {store} from './store/store.js'
import VueResource from 'vue-resource'

Vue.use(VueResource);

Vue.config.productionTip = false

new Vue({
  el: "#app",
  store,
  render: h => h(App),
}).$mount('#app')
