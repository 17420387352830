import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state:{
        fullName:'', // fullName - userName
        address:'', // address - userAddress
        email:'', // email - userEmailAddress
        reservationNumber:'', // reservationNumber - registerNumber
        contact: "",

        isDataAccess: false, // isDataAccess - lawAccessData
        isDataCorrection: false, // isDataCorrection - lawRectificationData
        isDataRemoval: false, // isDataRemoval - lawDeleteData
        isDataRestriction: false, // isDataRestriction - lawProcessingData
        isDataExporting: false, // isDataExporting - lawTransferData
        isDataObjection: false, // isDataObjection - lawObjectionData
        isConsentWithdrawal: false, // isConsentWithdrawal - lawWithdrawAgreementData
        isConfirmation: false, // isConfirmation - lawConfirmData

        oldData: '', // oldData - actualData
        newData: '', // newData - newData
        legalBasis: '', // legalBasis - legalBasis
        newAdministrator:'', // newAdministrator - newAdministrator
        
    },
    getters:{
        getUserName: state =>{
            return state.fullName
        },
        getUserAddress: state =>{
            return state.address
        },
        getUserEmail: state =>{
            return state.email
        },
        getRegisterNumber: state =>{
            return state.reservationNumber
        },

        getLawRectificationData: state =>{
            return state.isDataCorrection
        },
        getLawProcessingData: state =>{
            return state.isDataRestriction
        },
        getLawTarnsferData: state =>{
            return state.isDataExporting
        },

        getActualData: state=>{
            return state.oldData;
        },
        getNewData: state=>{
            return state.newData;
        },
        getLegalBasis: state=>{
            return state.legalBasis;
        },
        getNewAdministrator: state=>{
            return state.newAdministrator
        }
    },
    mutations:{
        updateName: ( state, value)=>{
           state.fullName = value;
        },
        updateAddress: ( state, value)=>{
            state.address = value;
         },
         updateEmail: ( state, value)=>{
            state.email = value;
         },
         updateRegister: ( state, value)=>{
            state.reservationNumber = value;
         },

         updateActualData: (state,value)=>{
             state.oldData = value;
         },
         updateNewData: (state,value)=>{
            state.newData = value;
        },

        updateLegalBasis: (state,value)=>{
            state.legalBasis = value;
        },
        updateNewAdministrator: (state,value)=>{
            state.newAdministrator = value;
        },


         checkInput: (state,name)=>{
             state[name] = !state[name];
             if(name === 'isDataCorrection'){
                 state.oldData = "";
                 state.newData ="";
             }else if(name === 'isDataRestriction'){
                 state.legalBasis ="";
             }else if(name === 'isDataExporting'){
                 state.newAdministrator="";
             }
         }
    },

    actions:{
        updateName: ({commit},value) =>{
            commit('updateName',value);
        },
        updateAddress: ({commit},value) =>{
            commit('updateAddress',value);
        },
        updateEmail: ({commit},value) =>{
            commit('updateEmail',value);
        },
        updateRegister: ({commit},value) =>{
            commit('updateRegister',value);
        },

        updateActualData: ({commit},value)=>{
            commit('updateActualData',value);
        },
        updateNewData: ({commit},value)=>{
            commit('updateNewData',value);
        },
        updateLegalBasis: ({commit},value)=>{
            commit('updateLegalBasis',value);
        },
        updateNewAdministrator: ({commit},value)=>{
            commit('updateNewAdministrator',value);
        },

        checkInput: ({commit},name)=>{
            commit("checkInput",name);
        }
    }

})