<template>
  <div id="logo" class="text-left">
    <span class="site-name">
      <a
        href="https://formularze-daneosobowe.tui.pl/"
        title="getLogoTitle"
        rel="home"
      >Formularze TUI.pl</a>
    </span>

    <span class="site-description">formularze-daneosobowe.tui.pl</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LogoHeader",
};
</script>

<style lang="scss" scoped>
#logo{
  .site-name{
    display: block;
    font-size: 2.063em;
    line-height: 1.0em;
    padding-top: 20px;
    a{
      color: #333;
      font-weight: 700;
      text-decoration: none;
    }
  }

  .site-description{
    color: #afafaf;
    display: block;
    font-size: 0.875em;
    margin: 10px 0;
  }
}
</style>