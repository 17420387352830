<template>
  <div id="app" class="container">
    <logo-header />
    <form-registration />
  </div>
</template>

<script>
import LogoHeader from "./components/LogoHeader.vue";
import FormRegistration from "./components/FormRegistration.vue";

export default {
  name: "app",
  components: {
    LogoHeader,
    FormRegistration
  }
};
</script>

<style lang="scss">
body {
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #555555;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  -ms-filter: "progid:DXImageTransform.Microsoft.font-smoothing(antialiased)";
  line-height: 1.5em;
}

#rc-anchor-container {
  max-width: 310px !important;
}
</style>
